import gsap from 'gsap/dist/gsap';
import type { NextPage } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import DesktopIntro from '../components/desktopIntro/desktopIntro';
import { DesktopMenu } from '../components/desktopmenu/DesktopMenu';
import { MediaBlock } from '../components/general/blocks/mediaBlock/MediaBlock';
import { DefaultMediaBlockProps } from '../components/general/blocks/switchBlock/SwitchBlock';
import CustomMetaData, {
  CustomMetaDataProps,
} from '../components/general/customMetaData/CustomMetaData';
import MobileIntro from '../components/mobileIntro/mobileIntro';
import { useAppContext } from '../context/AppContext';
import { useWindowSize } from '../hooks/useWindowSize';
import { getAPI, isDesktopWidth, isMobileWidth } from '../utils/helpers';
import { getSharedData } from '../utils/parsers';
import { widont } from '../utils/typography';
import { ChapterType } from './chapter/[id]';
import style from './index.module.scss';

type Props = {
  chapters: ChapterType[];
  intro: { copy: string; label: string };
  endBlurb: { copy: string; label: string };
  images: DefaultMediaBlockProps[];
  metaData: CustomMetaDataProps;
};

const Home = ({ chapters, images, intro, endBlurb, metaData }: Props) => {
  const { setChapters } = useAppContext();
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const router = useRouter();
  const windowSize = useWindowSize();

  useEffect(() => {
    document.body.classList.add('u-bounce-remover');
  }, []);

  useEffect(() => {
    const desktop = isDesktopWidth(windowSize.width);
    setIsDesktop(desktop);
  }, [windowSize]);

  useEffect(() => {
    setChapters(chapters);
  }, [chapters]);

  return (
    <>
      <CustomMetaData {...metaData} />
      {isDesktop ? (
        <DesktopIntro intro={intro} endBlurb={endBlurb} images={images} />
      ) : (
        <MobileIntro intro={intro} endBlurb={endBlurb} images={images} />
      )}
    </>
  );
};
// This also gets called at build time
export async function getStaticProps({ params }: any) {
  // params contains the project `id`.
  // If the route is like /project/1, then params.id is 1

  const globalData = await getSharedData();
  const chapters = globalData.chapters;

  const pageRequest = await fetch(`${getAPI()}/homepage/`);
  const page = await pageRequest.json();
  const get_label = () => {
    return `<a href="${page.email}">${page.email}</a><br><span>${page.phone}</span>`
  }

  const props: Props = {
    chapters: chapters,
    images: page.images,
    intro: { copy: page.introduction, label: get_label() },
    endBlurb: { copy: page.content, label: get_label() },

    metaData: {
      title: page.meta_title,
      description: page.meta_description,
      image: page.meta_image,
    },
  };
  return {
    props: props,
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: NEXTJS_REVALIDATE_PAGE_IN_SECONDS, // In seconds
  };
}

export const NEXTJS_REVALIDATE_PAGE_IN_SECONDS = 10;
export default Home;
