import gsap from 'gsap/dist/gsap';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { MediaBlock } from '../../components/general/blocks/mediaBlock/MediaBlock';
import { DefaultMediaBlockProps } from '../../components/general/blocks/switchBlock/SwitchBlock';
import { useAppContext } from '../../context/AppContext';
import { setCSSVar } from '../../utils/helpers';
import { widont } from '../../utils/typography';
import style from './desktopIntro.module.scss';

type Props = {
  // chapters: ChapterType[];
  intro: { copy: string; label: string };
  endBlurb: { copy: string; label: string };
  images: DefaultMediaBlockProps[];
};

const DesktopIntro = ({ images, intro, endBlurb }: Props) => {
  const { showMenu, menuIsShowing, setHideUI } = useAppContext();
  const router = useRouter();

  const imageContainerLeftRef = useRef<HTMLDivElement>(null);
  const imageARef = useRef<HTMLDivElement>(null);
  const imageBRef = useRef<HTMLDivElement>(null);
  const contentRectRef = useRef<HTMLDivElement>(null);
  const blurbContentRef = useRef<HTMLDivElement>(null);
  const contactContentRef = useRef<HTMLDivElement>(null);
  const imageContainerRightRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<any>(null);
  const scrollRef = useRef<any>(null);
  const progressRef = useRef<number>(0);
  const scrollTweenRef = useRef<{ current: number }>({ current: 0 });

  const getMaxScroll = () => {
    const maxScroll = 0.25 * window.innerHeight; // - window.innerHeight;
    return maxScroll;
  };
  const onScroll = () => {
    if (!timelineRef.current) return;
    const pct = progressRef.current / getMaxScroll();

    if (pct >= 0.5) {
      if (!menuIsShowing) {
        showMenu(true);
      }
    } else {
      // if (menuIsShowing) {
      showMenu(false);
      // }
    }
    timelineRef.current.progress(pct);
  };
  const onDocClick = () => {
    const tween = { current: progressRef.current };
    gsap.to(tween, {
      current: getMaxScroll(),
      duration: 3,
      onUpdate: () => {
        progressRef.current = tween.current;
        scrollTweenRef.current.current = progressRef.current;
        onScroll();
      },
    });
  };
  useEffect(() => {
    progressRef.current = 0;
    setHideUI(true);
    document.body.classList.add('u-bounce-remover');
  }, []);

  scrollRef.current = gsap.quickTo(scrollTweenRef.current, 'current', {
    // duration: 1,
    ease: 'power1.easeOut',
    onUpdate: () => {
      progressRef.current = scrollTweenRef.current.current;
      onScroll();
    },
  });

  const onWheel = (e: WheelEvent) => {
    const animationSpeed = 1;
    const target = Math.max(
      0,
      Math.min(getMaxScroll(), progressRef.current + e.deltaY * animationSpeed),
    );
    // const tween = { current: progressRef.current };
    // scrollRef.current = gsap.quickTo(scrollTweenRef.current, 'current', {
    //   current: target,
    //   duration: 1,

    // });
    scrollRef.current(target);
  };

  useEffect(() => {
    if (router.asPath === '/') {
      window.addEventListener('wheel', onWheel);
    } else {
      window.removeEventListener('wheel', onWheel);
    }
    return () => {
      window.removeEventListener('wheel', onWheel);
    };
  }, [contentRectRef, menuIsShowing, router]);

  // useEffect(() => {
  //   if (router.asPath !== '/') {
  //     gsap.set('.js-menu-list-intro-hook', { clearProps: 'all' });
  //     document.querySelector('.js-menu-list-intro-hook')?.classList.remove('u-remove-animation');
  //     document.body.classList.remove('u-bounce-remover');
  //   }
  // }, [router]);

  useEffect(() => {
    gsap.set(contentRectRef.current, { x: window.innerWidth * 0.05 });
    gsap.set(contactContentRef.current, { x: 0 });
    gsap.set(blurbContentRef.current, { x: window.innerWidth });

    const timeline = gsap.timeline({ paused: true });
    timelineRef.current = timeline;

    timeline.to([contentRectRef.current], { x: 0, duration: 0.1 });
    timeline.to([contactContentRef.current], { x: 0, duration: 0.2 }, '<');
    timeline.to(
      [imageARef.current, imageBRef.current],
      { x: -window.innerWidth * 0.65, duration: 0.2 },
      '<',
    );
    timeline.to([contactContentRef.current], { autoAlpha: 0, display: 'none' });

    timeline.to([blurbContentRef.current], { x: 0, duration: 0.3 }, '<-=25%');
    timeline.to(
      '.js-menu-list-intro-hook',
      {
        x: '33%',
        duration: 0.3,
        onUpdate: () => {
          document.querySelector('.js-menu-list-intro-hook')?.classList.add('u-remove-animation');
        },
      },
      '<+=10%',
    );
    timeline.to([imageContainerRightRef.current], { duration: 0.02, alpha: 0 }, '>-0.25');
    // DEV NOTE: add a pause so we don't hit 100% right after the text is in place
    // timeline.to([blurbContentRef.current], { duration: 1 });
    progressRef.current = 0;
    onScroll();

    return () => {
      scrollRef.current?.tween.kill();
      timeline?.kill();
      timelineRef.current = null;

      const intro = document.querySelector('.js-menu-list-intro-hook');
      if (intro) {
        intro.removeAttribute('style');
      } else {
      }
      gsap.killTweensOf('.js-menu-list-intro-hook');
      gsap.to('.js-menu-list-intro-hook', { clearProps: 'all' });
      document.querySelector('.js-menu-list-intro-hook')?.classList.remove('u-remove-animation');
      showMenu(false);
    };
  }, [contentRectRef]);

  return (
    <div onClick={onDocClick} className={style.holder}>
      <div className={`${style.container}`}>
        <div ref={imageContainerLeftRef} className={style.imageContainerLeft}>
          <div ref={imageARef} className={style.imageFirst}>
            <MediaBlock
              media={images[0]}
              layout="fill"
              loading="eager"
              objectFit="cover"
              block_type="media"
              priority={true}
              sizes="40vw"
            />
          </div>
          <div ref={imageBRef} className={style.imageSecond}>
            <MediaBlock
              media={images[1]}
              layout="fill"
              loading="eager"
              objectFit="cover"
              block_type="media"
              priority={true}
              sizes="40vw"
            />
          </div>
        </div>
        <div ref={contentRectRef} className={style.whiteRect}>
          <div ref={contactContentRef} className={style.contentContact}>
            <div
              className={style.contentContactIntro}
              dangerouslySetInnerHTML={{ __html: intro.copy }}
            />
            <div
              className={style.contentContactLabel}
              dangerouslySetInnerHTML={{ __html: intro.label }}
            />
          </div>
          <div ref={blurbContentRef} className={style.contentBlurb}>
            <div
              className={style.contentBlurbIntro}
              dangerouslySetInnerHTML={{ __html: widont(endBlurb.copy) }}
            />
          </div>
        </div>
        <div ref={imageContainerRightRef} className={style.imageContainerRight}>
          <MediaBlock
            media={images[1]}
            layout="fill"
            loading="eager"
            objectFit="cover"
            block_type="media"
            priority={true}
            sizes="40vw"
          />
        </div>
      </div>
    </div>
  );
};

export default DesktopIntro;
