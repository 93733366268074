import gsap from 'gsap/dist/gsap';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { MediaBlock } from '../general/blocks/mediaBlock/MediaBlock';
import { DefaultMediaBlockProps } from '../general/blocks/switchBlock/SwitchBlock';
import { useAppContext } from '../../context/AppContext';
import { widont } from '../../utils/typography';
import style from './mobileIntro.module.scss';
import { useWindowSize } from '../../hooks/useWindowSize';
import { Logo } from '../desktopmenu/Logo';

type Props = {
  // chapters: ChapterType[];
  intro: { copy: string; label: string };
  endBlurb: { copy: string; label: string };
  images: DefaultMediaBlockProps[];
};

const MobileIntro = ({ images, intro, endBlurb }: Props) => {
  const { showMenu, menuIsShowing, setHideUI } = useAppContext();
  const router = useRouter();
  const size = useWindowSize();

  const timelineRef = useRef<any>(null);
  const scrollRef = useRef<any>(null);
  const progressRef = useRef<number>(0);
  const scrollTweenRef = useRef<{ current: number }>({ current: 0 });
  const scrollPositionRef = useRef<number>(0);

  const onScroll = () => {
    const pct = progressRef.current;
    timelineRef.current?.progress(pct);
  };

  useEffect(() => {
    if (menuIsShowing) {
      scrollPositionRef.current = window.scrollY;
    } else {
      window.scrollTo(0, scrollPositionRef.current);
    }
  }, [menuIsShowing]);

  useEffect(() => {
    progressRef.current = 0;
    setHideUI(true);
    document.body.classList.add('u-bounce-remover');
  }, []);

  useEffect(() => {
    // gsap.set('.js-mobile-panelA', { x: 0 });
    // gsap.set('.js-mobile-panelB', { x: window.innerWidth });
    // gsap.set('.js-mobile-panelC', { x: 2 * window.innerWidth });
    // gsap.set('.js-mobile-panelD', { x: 3 * window.innerWidth });
    // // progressRef.current = 0;
    // gsap.delayedCall(1, onScroll);
    // onScroll();
  }, [size.height, size.width]);

  scrollRef.current = gsap.quickTo(scrollTweenRef.current, 'current', {
    duration: 1,
    ease: 'power3.easeInOut',
    onUpdate: () => {
      progressRef.current = scrollTweenRef.current.current;
      onScroll();
    },
  });

  const onWheel = (e: Event) => {
    if (menuIsShowing) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    const target = window.scrollY / (4 * window.innerHeight - window.innerHeight);
    // const tween = { current: progressRef.current };
    // scrollRef.current = gsap.to(tween, {
    //   current: target,
    //   duration: 0,
    //   onUpdate: () => {
    //     progressRef.current = tween.current;
    //     onScroll();
    //   },
    // });
    scrollRef.current(target);
  };

  useEffect(() => {
    if (router.asPath === '/') {
      window.addEventListener('scroll', onWheel);
    } else {
      window.removeEventListener('scroll', onWheel);
    }
    return () => {
      window.removeEventListener('scroll', onWheel);
    };
  }, [menuIsShowing, router]);

  useEffect(() => {
    gsap.set('.js-mobile-panelA', { x: 0 });
    gsap.set('.js-mobile-panelB', { x: window.innerWidth });
    gsap.set('.js-mobile-panelC', { x: 2 * window.innerWidth });
    gsap.set('.js-mobile-panelD', { x: 3 * window.innerWidth });

    const timeline = gsap.timeline({ paused: true });
    timeline.to('.js-mobile-panelB', {
      x: 0,
    });
    timeline.to(
      '.js-mobile-panelC',
      {
        x: 0,
      },
      '<90%',
    );
    timeline.to(
      '.js-mobile-panelD',
      {
        x: 0,
      },
      '<90%',
    );
    timelineRef.current = timeline;
    progressRef.current = progressRef.current || 0;
    onScroll();

    return () => {
      showMenu(false);
      timeline.kill();
      scrollRef.current?.tween.kill();
      timelineRef.current = null;
    };
  }, [size.width]);

  const introStripped = intro.copy.replace('<br />', ' ');

  return (
    <div className={style.holder}>
      <div className={style.panels}>
        <div className={`${style.panel} js-mobile-panelA`}>
          <div className={style.image}>
            <MediaBlock
              media={images[0]}
              layout="fill"
              loading="eager"
              objectFit="cover"
              block_type="media"
            />
          </div>
          <div className={style.logo}>
            <Logo alignLeft={true} />
          </div>
        </div>

        <div className={`${style.panel} ${style.panelIntro} js-mobile-panelB`}>
          <div className={style.introContainer}>
            <div
              className={`${style.contentContactIntro} ${style.textContent}`}
              dangerouslySetInnerHTML={{ __html: introStripped }}
            />
            <div
              className={`${style.contentContactLabel} ${style.textContent}`}
              dangerouslySetInnerHTML={{ __html: intro.label }}
            />
          </div>
        </div>
        <div className={`${style.panel} js-mobile-panelC`}>
          <div className={style.image}>
            <MediaBlock
              media={images[1]}
              layout="fill"
              loading="eager"
              objectFit="cover"
              block_type="media"
            />
          </div>
        </div>
        <div className={`${style.panel} js-mobile-panelD`}>
          <div
            className={`${style.contentBlurbIntro} ${style.textContent}`}
            dangerouslySetInnerHTML={{ __html: widont(endBlurb.copy) }}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileIntro;
